.navbar {
    background: linear-gradient(
      90deg,
      white, 
      white
    );
    height: 80px;
    display: flex;
    align-items: center;
    font-size: 1.2rem;
    position: sticky;
    top: 0;
    z-index: 99;
  }
  
  .navbar-container {
    width: 100%;
    display: inline-block;
    align-items: center;
    height: 80px;
    box-shadow: 0 10px 20px 1px rgba(0,0,0,.02);
  }
  
  .navbar-text {
    color: black;
    justify-self: flex-start;
    margin-left: 20px;
    cursor: pointer;
    text-decoration: none;
    font-size: 1rem;
    display: flex;
    align-items: center;
    font-family: "futuraLightBT";
    font-weight: 600;
  }
  
  .left-link{
    display: inline-block;
    width: 60px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  
  .home-container{
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
  }
  
  .home-link {
    color: #37793D;
    font-family: "futuraHeavy";
    font-size: 14px;
    letter-spacing: 0.3rem;
    text-align: center;
    margin-left: 6.1rem;
    justify-content: center;
    align-items: center;
  }
  
  .navbar-logo {
    height: 40px;
    margin-left:  3rem;
  }
  
  .fa-typo3 {
    margin-left: 0.5rem;
    font-size: 1.8rem;
  }
  
  .nav-grid-container {
    display:inline-block;
    justify-content: right;
    float: right;
    padding-right: 2rem;
  }
  
  .nav-grid {
    display: grid;
    grid-template-columns: repeat(7, auto);
    list-style: none;
    text-align: center;
  }
  
  .nav-item {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 80px;
  }
  
  .nav-links {
    color: black;
    padding: 0.5rem 2rem;
    text-decoration: none;
  }

  .nav-links * {
    font-family: "futuraHeavy";
    font-size: 12px;
    letter-spacing: 0.3rem;
  }
  
  .hover-button {
    border: none;
    background-color: transparent;
  }
  
  .fa-bars {
    color: black;
  }
  
  .nav-links-mobile {
    display: none;
  }
  
  .menu-icon {
    display: none;
  }
  
  .calendar-button {
    width:40px;
    height: 40px;
    font-size: 15px;
    text-align: center;
    background-color: transparent;
    border: none;
    cursor: pointer;
  }
  
  .room-reservation-button {
    width:40px;
    height: 40px;
    font-size: 15px;
    text-align: center;
    background-color: transparent;
    border: none;
    cursor: pointer;
  }
  
  .mobile-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
  }

  .mobile-dropdown-menu {
    color: white;
    list-style: none;
    margin-left: 4rem;
}

.mobile-dropdown-link {
    display: block;
    height: auto;
    width: 100%;
    text-decoration: none;
    text-align: left;
    color: black;
    font-size: 12px;
    padding: 0.75rem 8px;
    font-family: "futuraHeavy";
    letter-spacing: 00.3rem;
  }

  .dropdown-menu {
    width: 200px;
    position: absolute;
    top: 80px;
    color: white;
    list-style: none;
    text-align: start;
  }
  
  .dropdown-menu li {
    background: white;
    cursor: pointer;
  }
  
  .dropdown-menu.clicked {
    display: none;
  }
  
  .dropdown-link {
    display: block;
    height: auto;
    width: 100%;
    text-decoration: none;
    text-align: center;
    color: black;
    font-size: 12px;
    padding: 0.75rem 8px;
    font-family: "futuraHeavy";
    letter-spacing: 0.15rem;
  }
  
  .dropdown-link:hover {
    color: #8CC292;
  }
  
  @media screen and (max-width: 960px) {
    .fa-caret-down {
      display: none;
    }
  }
  
  
  @media screen and (max-width: 1000px) {
    .NavbarItems {
      position: relative;
    }
    .nav-menu {
      background-color: white;
      display: flex;
      flex-direction: column;
      width: auto;
      height: 100vh;
      position: absolute;
      opacity: 1;
      right: 0;
      top: 0;
      padding: 4rem 0 ;
      transition: all 0.5s ease;
      box-shadow: 0px 0px 10px 5px rgba(0,0,0,.05);
      overflow: scroll;
    }
    
  
    .nav-menu.active {
      background: #242222;
      left: 0;
      opacity: 1;
      transition: all 0.5s ease;
      z-index: 1;
    }
  
    .nav-item {
      height: auto;
      align-items: flex-start;
    }
  
    .nav-links {
      color: black;
      text-align: left;
      padding: 2rem;
      width: 100%;
      display: table;
    }
  
    .navbar-logo {
      position: absolute;
      top: 0;
      left: 0;
      transform: translate(25%, 50%);
    }
  
    .menu-icons {
      display: grid;
      grid-template-columns: repeat(2, auto);
      column-gap: 2rem;
      position: absolute;
      top: 5px;
      right: -2rem;
      transform: translate(-100%, 80%);
      font-size: 1.8rem;
      cursor: pointer;
    }
  
    .expand-icon{
      font-size: 18px;
      margin: 0 0.25rem;
    }
  
    .nav-links-mobile {
      display: flex;
      align-items: center;
      font-family: futuraHeavy;
      text-align: left;
      color: black;
      transition: all 0.3s ease-out;
      text-decoration: none;
      font-size: 18px;
      padding-top: 1rem;
      padding-bottom: 1rem;
      cursor: pointer;
    }
  
    .nav-link-padding {
      padding-left: 4rem;
      padding-right: 4rem;
    }
    .nav-link-expand {
      padding-left: 2.4rem;
      padding-right: 4rem;
      background-color: transparent;
      border: none;
    }
  }
  