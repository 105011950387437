h1 {
    text-align: center;
    margin-bottom: 2%;
    margin-top: 1%;
}

h2 {
    margin-bottom: 0.5%;
    margin-top: 1%;
    margin-left: 2%;
}

p {
    margin-left: 2%;
}

li {
    margin-left: 3%;
}