#sgcard{
    width: 239px;
    height: 311px;
    padding-top: 1rem;
    padding-bottom: 1rem;
    margin: 20px;
    border-radius: 30px;
}

#sgcardinner{
    text-align: center;
    position: relative;
    width: 100%;
    height: 100%;
    transition: transform 0s;
    transform-style: preserve-3d;
}

#sgcard:hover #sgcardinner{
    transform: rotateY(180deg);
}

#sgcardfront, #sgcardback{
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden; /* Safari */
    backface-visibility: hidden;
    border-radius: 30px;
}

#sgcardback{
    transform: rotateY(180deg);
    box-shadow: 0 0 20px #d3d3d3;
    text-align: center;
    padding: 2.5rem 2rem;
    background-color: #FFFFFF;
}

#stugovimage{
    object-fit: cover;
    /* Need input for following three parameters 
    to resize avatar to fit */
    /* object-position: -10px 0px;
    max-width: 110%;
    max-height: 110%; */
}

#sgimg-wrapper{
    width: 150px;
    height: 150px;
    border-radius: 100%;
    display: block;
    overflow: hidden;
    margin: auto;
}

#sgname{
    margin-top: 15px;
    margin-bottom: 8px;
    font-family: 'futuraHeavy';
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 15px;
    text-align: center;
    letter-spacing: 0.35em;
    text-transform: uppercase;

    color: #000000;
}

#sgposition{
    font-family: 'futuraMediumBT';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    letter-spacing: 0.22em;
    text-transform: uppercase;

    color: #000000;
}

#sgemail{
    font-family: 'mpBlkLt';
    font-style: italic;
    font-weight: 300;
    font-size: 12px;
    line-height: 18px;
    text-align: center;

    color: #000000;
    cursor: pointer;
    text-decoration: none;
}

#sgbname{
    font-family: 'futuraMediumBT';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 12px;
    text-align: center;
    letter-spacing: 0.35em;
    text-transform: uppercase;

    color: #000000;
}

#sgbpronouns{
    font-family: 'futuraMediumBT';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 13px;
    text-align: center;
    letter-spacing: 0.35em;
    text-transform: uppercase;

    color: #000000;
    margin: 0.25rem 0;
}

#sgbmajor{
    font-family: 'futuraMediumBT';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 13px;
    text-align: center;
    letter-spacing: 0.35em;

    color: #000000;
    margin-bottom: 2rem;
}

#sgbdesc{
    font-family: 'futuraMediumBT';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 12px;
    /* identical to box height */

    text-align: center;
    letter-spacing: 0.35em;
    text-transform: uppercase;

    color: #000000;
    margin-bottom: 0.5rem;
}

#sgbshortdesc{
    margin-bottom: 8px;

    font-family: 'mpBlkLt';
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 18px;
    text-align: center;

    color: #000000;
}