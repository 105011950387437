.coord-name{
    font-family: "futuraHeavy";
    font-size: 13px;
    letter-spacing: 2px;
}
.coord-bio{
    font-family: "mpBlkLt";
    font-size: 20px;
    margin-top: -4px;
}
.border-container{
    border-radius:100%;
    width:250px; height:250px;
    display: flex;
    align-items: center;
    justify-content: center; 
    overflow: hidden;
    margin:1rem 0;
}

.opacity-zero{
    opacity: 0;
}

.background-img {
    height: 250px;
    width: 250px;
    border-radius: 100%;
}

.text-bg {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 250px;
    width: 250px;
    background-color: white;
    padding: 2rem;
}
.text-container {
    text-align: center;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width: 1080px) {
    .border-container{
        border-radius:100%;
        width:240px; height:240px;
        display: flex;
        align-items: center;
        justify-content: center; 
        overflow: hidden;
        margin:1rem 0;
    }
    .background-img {
        height: 240px;
        width: 240px;
        border-radius: 100%;
    }
    .text-bg {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 240px;
        width: 240px;
        background-color: white;
        padding: 2rem;
    }
}