@font-face {
  font-family: futuraHeavy;
  src: url(../public/fonts/futura/Futura\ Heavy\ font.ttf)
}

@font-face {
  font-family: futuraMediumBT;
  src: url(../public/fonts/futura/futura\ medium\ bt.ttf)
}

@font-face {
  font-family: mpBlkLt;
  src: url(../public/fonts/meta-pro/FFMetaProLight.TTF)
}

@font-face {
  font-family: mpReg;
  src: url(../public/fonts/meta-pro/FFMetaProRegular.TTF)
}
@font-face {
  font-family: futuraBook;
  src: url(../public/fonts/futura/Futura\ Book\ font.ttf)
}
@font-face {
  font-family: futuraLightBT;
  src: url(../public/fonts/futura/futura\ light\ bt.ttf)
}
@font-face {
  font-family: futuraXBlkBT;
  src: url(../public/fonts/futura/Futura\ XBlk\ BT.ttf)
}


* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: Arial, Helvetica, sans-serif;
}

.home,
.services,
.products,
.sign-up {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

.exit-icon{
  position: absolute;
  height: 30px;
  width: 30px;
  color: #909090;
  top:1rem;
  right:1rem;
}
.exit-button {
  border: none;
  background-color: transparent;
  cursor: pointer;
}

h1, h2, h3, p, div {
  font-display: block;
}

@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';