.advisor-modal-container {
    display: flex;
    flex-direction: column; 
    background-color: white;
    border-radius: 5px;
    padding: 2rem 2rem;
    position: absolute;
    top: 50%;
    left: 50%;
    max-height: 70vh;
    transform: translate(-50%, -65%);
    margin-top: 80px;
    width: fit-content;
    box-shadow: 0px 0px 10px 5px rgba(0,0,0,.02);
    overflow-y: scroll;
    overflow-x: hidden;
    margin-top: 80px;
}

.a-group-name {
    font-family: "futuraMediumBT";
    font-size: 15;
    letter-spacing: 2px;
    padding-bottom: 2rem;
    text-align: center;
}

.a-grid {
    display: grid;
    grid-template-columns: repeat(2, 350px);
    row-gap: 2rem;
    column-gap: 1rem;
}

.a-container {
    display: flex;
    flex-direction: row;
    width: fit-content;
}

.advisor-img {
    height: 150px;
    min-width: 150px;
    border-radius: 100%;
}

.a-majors{
    font-family: "mpBlkLt";
    font-size: 13px;
    padding: 0.5rem;
}

.a-email {
    font-family: "mpBlkLt";
    font-style: italic;
    font-size: 11px;
    padding: 0.5rem;
}

.a-name{
    font-family: "futuraBook";
    font-size: 15px;
    letter-spacing: 2px;
    padding: 0.5rem;
}

@media only screen and (max-width: 1100px) {
    .advisor-modal-container {
        padding: 2rem 2rem;
        margin-top: 80px;
    }
}
@media only screen and (max-width: 940px) {
    .advisor-modal-container {
        padding: 2rem 2rem;
    }
    .a-grid {
        display: flex;
        flex-direction: column;
        row-gap: 3rem;
        column-gap: 0;
    }
}