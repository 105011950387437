.headflex-row{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #F3F8F4;;
    position: relative;
    width: 100%;
    padding-top: 5rem;
    padding-bottom: 2rem;
}

.associatesintro-text{
    text-align: center;
    /* width: 33%; */
    width: 50%;
}

.associatesintro-text h2 {
    /* Heading 2 */
    font-family: 'futuraMediumBT';
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    line-height: 25px;
    letter-spacing: 0.35em;
    text-transform: uppercase;

    color: #000000;
    margin-bottom: 0.5rem;
}

.aasectiondesc {
    font-family: 'mpBlkLt';
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    text-align: center;

    color: #000000;
}

.joinassociates{
    position: absolute; 
    top: 20px;
    right:20px;
    border-radius: 100%;
    text-align: center;
    font-style: italic;
    background-color: #FFFFFF;
    width: 13rem;
    height: 13rem;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 2rem;
}

.joinassociates-text {
    font-family: 'mpBlkLt';
    font-style: italic;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    text-align: center;

    color: #000000;
}

.introflex-row{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-start;
    background-color: #FFFFFF;
    position: relative;
    width: 55%;
    margin: auto;
    padding: 4rem 5rem;
    gap: 4rem;
}

.introflex-row h3 {
    font-family: 'futuraMediumBT';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: 0.39em;
    text-transform: uppercase;

    color: #000000;
}

.aatextdesc {
    font-family: 'mpBlkLt';
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    text-align: justify;

    color: #000000;
}

.associatesintro-bg{
    background: linear-gradient(0deg, #ffffff 50%, #F3F8F4 50%);
    bottom: 0px;
}

.associatescard-flex{
    width: 70%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    /* align-items: center; */
    align-content: center;
    margin: auto;
    padding: 4rem 0rem;
}

#aaslidewrapper{
    justify-content: center;
    align-items: center;
}

#aaslidedeck{
    width: 70%;
    display: block;
    margin: 5rem auto;
}

.famousassociates-wrapper{
    justify-content: center;
    align-items: center;
    background: #F3F8F4;;
    position: relative;
    width: 100%;
    padding-top: 10rem;
    padding-bottom: 10rem;
}

.famousassociates-text{
    text-align: center;
    width: 60%;
    margin: auto;
}

.famousassociates-text h3 {
    /* Heading 3 */

    font-family: 'futuraMediumBT';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 20px;
    /* identical to box height */

    text-align: center;
    letter-spacing: 0.39em;
    text-transform: uppercase;

    color: #000000;
}

.ad-flexbox{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 11rem 0rem;
    margin-bottom: 3rem;
    gap: 8%;
    overflow: hidden;
}

.ad-image{
    max-height: 350px;
    box-shadow: -25px 25px 0px #8CC292;
    margin-left:3rem;
}

.ad-contents {
    padding-right:3rem;
}

#ad-title{
    font-weight: bold;
    font-style: italic;
    font-family: 'futuraHeavy';
    font-size: 25px;
    line-height: 29px;
    display: flex;
    align-items: center;
    text-align: center;

    color: #2B7632;
    padding: 1rem 0;
}

.mail-button {
    background-color: #FFFFFF;
    border: 1px solid #2B7632;
    border-radius: 12px;
    font-family: 'mpReg';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 27px;
    letter-spacing: 0.01em;
    color: #2B7632;
    cursor: pointer;
    padding: 0.5rem 2rem;
    margin: 1.5rem 0;
}


.mail-button:hover{
    background-color: #2B7632;
    color: white;
}

.mail-buttonclicked{
    background-color: #2B7632;
    opacity: 0.5;
    font-family: 'mpReg';
    font-style: italic;
    font-weight: 700;
    font-size: 18px;
    line-height: 27px;

    letter-spacing: 0.01em;

    color: #FFFFFF;
}

#ad-comments{
    font-style: italic;
    margin: 1rem 0;
    font-family: 'mpBlkLt';
    font-style: italic;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;

    color: #000000;
}

.jonesemail{
    cursor: pointer;
    text-decoration: underline;
    font-family: 'mpBlkLt';
    font-style: italic;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    /* color: rgba(136,178,140,255); */
    color: #2B7632;
}

#ad-list li{
    list-style-position: inside;
}

.donate{
    background-color: white;
    border: none;
    cursor: pointer;
    box-shadow: 0px 4px 20px 3px rgba(214, 231, 216, 0.5);
    border-radius: 46px;
    z-index: 8;
    position: fixed;
    bottom: 2.8rem;
    right: 3.5rem;
    padding: 1rem 3rem;

    font-family: 'futuraHeavy';
    font-style: normal;
    font-weight: 900;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.25em;
    color: #2B7632;
}

.donate:hover{
    /* more agressive blurr */
    box-shadow: 0px 4px 50px 2px #D6E7D8;
    color: #8CC292;
}

.donate.donateclicked{
    background: #8CC292;
    color: white;
    box-shadow: none;
}

@media screen and (max-width: 1000px) {
    .headflex-row{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: #F3F8F4;;
        position: relative;
        width: 100%;
        padding-top: 5rem;
        padding-bottom: 2rem;
    }
    .joinassociates{
        position: relative; 
        border-radius: 100%;
        text-align: center;
        font-style: italic;
        background-color: #FFFFFF;
        width: 13rem;
        height: 13rem;
        padding: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 2rem;
    }

    .donate{
        background-color: white;
        border: none;
        cursor: pointer;
        box-shadow: 0px 4px 20px 3px rgba(214, 231, 216, 0.5);
        border-radius: 46px;
        z-index: 8;
        position: fixed;
        bottom: 2.8rem;
        right: 3.5rem;
        padding: 1rem 2rem;
    
        font-family: 'futuraHeavy';
        font-style: normal;
        font-weight: 900;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0.25em;
        color: #2B7632;
    }

}

@media screen and (max-width: 768px) {
    .introflex-row{
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: flex-start;
        background-color: #FFFFFF;
        position: relative;
        width: 60%;
        margin: auto;
        padding: 3rem;
        gap: 2rem;
    }

    .ad-flexbox{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 11rem 0rem;
        margin-bottom: 3rem;
        gap: 8%;
        overflow: hidden;
    }

    .ad-image{
        max-height: 200px;
        box-shadow: -25px 25px 0px #8CC292;
        margin-left:3rem;
    }
    .ad-contents {
        padding:4rem;
    }

    [class="donate"] {
        display: none;
      }

}
