.historyitem-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 20px;

  height: 500px;

  /* Inside auto layout */
  flex: 1;
  flex-grow: 1;
}

.history-photo {
  width: 260px;
  height: 180px;

  /* border-radius: 169px; */

  /* Inside auto layout */
  flex: none;
  flex-grow: 0;
}

.history-text-container {
  /* Auto layout */
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 0px;

  width: 100%;
  height: 200px;

  /* Inside auto layout */
  order: 1;
  align-self: stretch;
}

.history-text-container > h1 {
  height: 40px;
  width: 100%;

  /* Subheading */

  font-family: "futuraMediumBT";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  text-align: center;
  letter-spacing: 0.475em;
  text-transform: uppercase;

  color: #000000;

  /* Inside auto layout */

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.history-text-container > p {
  height: 171px;
  width: 100%;

  font-family: "mpBlkLt";
  font-style: normal;
  font-weight: 200;
  font-size: 12px;
  line-height: 19px;
  text-align: justify;

  color: #000000;

  /* Inside auto layout */
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}
