.traditions-container {
  /* Auto layout */

  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 100px 250px 200px 250px;
  gap: 40px;

  position: relative;
  width: 100%;
  /* height: 864px; */

  top: 10px;

  background-color: #f3f8f4;
}

.traditions-container > h1 {
  /* Heading 3 */
  width: 216px;
  height: 29px;

  /* Heading 3 */
  font-family: "futuraMediumBT";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  letter-spacing: 0.39em;
  text-transform: uppercase;

  color: #000000;

  /* Inside auto layout */

  flex: none;
  order: 0;
}

.traditions {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 50px;

  width: 100%;

  /* Inside auto layout */
  flex: none;
  order: 1;
  align-self: stretch;
}

.tradition-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
  gap: 41px;

  height: 547px;

  /* Inside auto layout */
  flex: 1;
  flex-grow: 1;
}

.photo {
  width: 235px;
  height: 235px;
  border-radius: 50%; 
  
  overflow: hidden;
  background-size: cover; 
  background-position: center; 
  background-repeat: no-repeat; 

  /* Inside auto layout  */
  flex: none;
  flex-grow: 0;
}

.text-container {
  /* Auto layout */
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 20px;

  width: 100%;
  height: 237px;

  /* Inside auto layout */
  order: 1;
  align-self: stretch;
}

.text-container > h1 {
  height: 40px;
  width: 100%;

  /* Subheading */

  font-family: "futuraHeavy";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.475em;
  text-transform: uppercase;

  color: #000000;

  /* Inside auto layout */

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.text-container > p {
  height: 171px;
  width: 100%;

  font-family: "mpBlkLt";
  font-style: normal;
  font-weight: 200;
  font-size: 13px;
  line-height: 19px;
  text-align: justify;

  color: #000000;

  /* Inside auto layout */
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}


@media only screen and (max-width: 930px) {
  [class="traditions"] {
    flex-direction: column;
    gap: 200px;
  }
}
