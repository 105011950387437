.oweek-container{
    display: flex;
    flex-direction: row;
    background-color: #F7F2FF;
    justify-content: center;
    align-items: center;
}

.coord-column{
    display: flex;
    flex-direction: column;
}
.coord-row{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin:0 3rem;
}
.oweek-text-container {
    background-color: white;
    padding: 3rem 6rem;
    margin-right: 6rem;
    margin-left: 4rem;
    margin-bottom: 2rem;
}

.oweek-h1{
    font-family: "futuraXblkBT";
    color: black;
    font-style: italic;
    font-size: 70px;
    letter-spacing: 6px;
    padding: 2rem 0;
}

.oweek-h2 {
    font-family: "futuraHeavy";
    font-size: 32px;
    text-align: center;
    padding-top: 2rem;
    padding-bottom: 3rem;
    letter-spacing: 6px;
}

.oweek-h3 {
    font-family: "futuraHeavy";
    font-size: 15px;
    text-align: left;
    padding-top: 1rem;
    letter-spacing: 6px;
}

.oweek-h4 {
    font-family: "futuraMediumBT";
    font-size: 15px;
    text-align: left;
    padding-top: 3rem;
    letter-spacing: 6px;
}

.oweek-p {
    font-family: "mpBlkLt";
    font-size: 13px;
    text-align:justify;
}

.oweek-letter-box {
    max-height: 350px;
    padding-top: 1rem;
    overflow: hidden;
    -webkit-mask-image: linear-gradient(to bottom, black 50%, transparent 100%);
    mask-image: linear-gradient(to bottom, black 50%, transparent 100%);
}
.open-letter-text {
    text-decoration: underline;
    text-align: center;
    font-style: italic;
    font-size: 13px;
    cursor: pointer;
    background-color: transparent;
    border: none;
}

.o-week-grid-container {
    display: flex;
    justify-content: center;
    padding: 5rem 0;
    flex-direction: column;
    align-items: center;
}

.o-week-group-grid {
    display: grid;
    grid-template-columns: repeat(3, 300px);
    row-gap: 2rem;
    column-gap: 2rem; 
}

.oweek-affiliate-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0 2rem 3rem 2rem;
    background-color: #f8f8f8;
    margin: 0 4rem;
    border-radius: 20px;
}

.oweek-paa-grid {
    display: grid;
    grid-template-columns: repeat(4, 160px);
    column-gap: 6rem;
    padding-top: 2rem;
}

.oweek-affiliate-profile {
    display: flex;
    align-items: center;
    flex-direction: column;
}
.oweek-affiliate-img {
    height: 140px;
    border-radius: 100%;
    margin-bottom: 1rem;
}

.oweek-affiliate-majors{
    font-family: "mpBlkLt";
    font-size: 13px;
    padding: 0.25rem 0;
    text-align: center;
}

.oweek-affiliate-email {
    font-family: "mpBlkLt";
    font-style: italic;
    font-size: 11px;
    padding: 0.25rem 0;
}

.oweek-affiliate-name {
    font-family: "futuraBook";
    font-size: 15px;
    letter-spacing: 2px;
    padding: 0.5rem;
    text-align: center;
}

.oweek-gopher-grid {
    display: grid;
    grid-template-columns: repeat(1, 160px);
    column-gap: 6rem;
    padding-top: 2rem;
}

.oweek-book-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: auto;
    background-color: #323232;
    padding: 2rem;
    margin-top: 4rem;

}

.book-title-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-bottom: 2rem;
}

.oweek-book-title {
    font-family: "futuraXblkBT";
    color: white;
    font-style: italic;
    font-size: 60px;
    letter-spacing: 4px;
}

.book-download-button {
    border: none;
    background-color: transparent;
    margin-left: 1.5rem;
}

.book-download-icon {
    font-size: 50px;
    color: white;
    cursor: pointer;
}

.oweek-flipbook {
    height: 600px;
    width: 70vw;
}

@media screen and (max-width: 1160px) {
    .oweek-container{
        display: flex;
        flex-direction: row;
        background-color: #F7F2FF;
        justify-content: center;
        align-items: flex-start;
    }
    .o-week-group-grid {
        display: grid;
        grid-template-columns: repeat(2, 300px);
        row-gap: 2rem;
        column-gap: 2rem;
    }
    .oweek-paa-grid {
        display: grid;
        grid-template-columns: repeat(2, 160px);
        column-gap: 6rem;
        padding-top: 2rem;
    }
    .coord-row{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0;
        min-width:0;
    }
    .oweek-text-container {
        background-color: white;
        padding: 3rem 4rem;
        margin-right: 4rem;
        margin-left: 4rem;
        margin-bottom: 2rem;
    }
    .oweek-h1{
        font-family: "futuraXblkBT";
        color: black;
        font-style: italic;
        font-size: 60px;
        letter-spacing: 6px;
        padding: 2rem 0;
    }
    .oweek-h2 {
        font-family: "futuraHeavy";
        font-size: 24px;
        text-align: center;
        padding-top: 2rem;
        padding-bottom: 3rem;
        letter-spacing: 6px;
    }
    .oweek-h4 {
        font-family: "futuraMediumBT";
        font-size: 13px;
        text-align: left;
        padding-top: 3rem;
        letter-spacing: 4px;
    }
    .oweek-letter-box {
        max-height: 450px;
        padding-top: 1rem;
        overflow: hidden;
        -webkit-mask-image: linear-gradient(to bottom, black 50%, transparent 100%);
        mask-image: linear-gradient(to bottom, black 50%, transparent 100%);
    }
}

@media screen and (max-width: 768px) {
    .o-week-group-grid {
        display: grid;
        grid-template-columns: repeat(1, 300px);
        row-gap: 2rem;
        column-gap: 2rem;
    }
    .oweek-paa-grid {
        display: grid;
        grid-template-columns: repeat(1, 160px);
        column-gap: 6rem;
        padding-top: 2rem;
    }
    .oweek-gopher-grid {
        display: grid;
        grid-template-columns: repeat(1, 160px);
        column-gap: 6rem;
        padding-top: 2rem;
    }
    .oweek-text-container {
        background-color: white;
        margin-right: 0;
        margin-left: 0;
        margin-bottom: 2rem;
    }
    .oweek-h1{
        font-family: "futuraXblkBT";
        color: black;
        font-style: italic;
        font-size: 40px;
        letter-spacing: 2px;
        padding: 0;
    }
    .oweek-h2 {
        font-family: "futuraHeavy";
        font-size: 18px;
        text-align: center;
        padding-top: 2rem;
        padding-bottom: 3rem;
        letter-spacing: 6px;
    }
    .oweek-h3 {
        font-family: "futuraHeavy";
        font-size: 13px;
        text-align: left;
        padding-top: 1rem;
        letter-spacing: 2px;
    }
    .oweek-letter-box {
        max-height: 50vh;
        padding-top: 1rem;
        overflow: hidden;
        -webkit-mask-image: linear-gradient(to bottom, black 50%, transparent 100%);
        mask-image: linear-gradient(to bottom, black 50%, transparent 100%);
    }
    .oweek-book-title {
        font-family: "futuraXblkBT";
        color: white;
        font-style: italic;
        font-size: 40px;
        letter-spacing: 4px;
    }
    .book-download-icon {
        font-size: 30px;
        color: white;
        cursor: pointer;
        margin-left: 0rem;
    }
    .oweek-book-title {
        font-family: "futuraXblkBT";
        color: white;
        font-style: italic;
        font-size: 30px;
        letter-spacing: 4px;
    }
    .oweek-flipbook {
        height: 400px;
        width: 70vw;
    }
    [class="coord-display"] {
        display: none;
      }
    
}