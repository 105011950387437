.tooltip-trigger .tooltip{
    text-align: center;
    border-radius: 6px;
    background-color: black;
    padding: 5px;
    opacity: 0;
    position: absolute;
    z-index: 1;
}

.tooltip-trigger:hover .tooltip {
    opacity:1;
}

.tooltip-trigger {
    position: relative;
    display: inline;
}

.tooltip {
    display: flex;
    flex: 1;
    font-size: 12px;
    color: white;
    top:100%;
    left:0%;
    letter-spacing: 0.5px;
}