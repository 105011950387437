.letter-modal {
    position: absolute;
    margin-top: 80px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -65%);
    background-color: white;
    border: 2px solid #e7e7e7;
    border-radius: 5px;
    box-shadow: 24;
    padding: 3rem 6rem;
    width: 60vw;
    overflow: scroll;
    max-height: 70vh;
}

.letter-h1{
    font-family: "futuraXBlkBT";
    color: black;
    font-style: italic;
    font-size: 70px;
    letter-spacing: 6px;
    padding: 2rem 0;
    text-align: center;
}

.letter-h3 {
    font-family: "futuraHeavy";
    font-size: 15px;
    text-align: left;
    letter-spacing: 6px;
    padding: 1rem 0;
}

.letter-p * {
    font-family: "mpBlkLt";
    font-size: 13px;
    text-align: justify;
}

.letter-contact {
    font-family: "mpBlkLt";
    font-size: 13px;
    text-align: left;
}

@media only screen and (max-width: 768px) {
    .letter-modal {
        position: absolute;
        margin-top: 80px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -65%);
        background-color: white;
        border: 2px solid #e7e7e7;
        border-radius: 5px;
        box-shadow: 24;
        padding: 2rem 2rem;
        width: 70vw;
        overflow: scroll;
        max-height: 70vh;
    }
    .letter-h1{
        font-family: "futuraXBlkBT";
        color: black;
        font-style: italic;
        font-size: 40px;
        letter-spacing: 4px;
        padding: 0rem 0;
        text-align: center;
    }

}