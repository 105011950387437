.footer-container {
  background-color: #ffffff;
  padding: 3rem 0 2rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  margin-top: 30px;
}

.footer-h-font {
  font-family: 'futuraBook';
  letter-spacing: 2px;
  font-size: 13px;
  color: black;
}

.footer-p-font {
  font-family: 'futuraBook';
  font-size: 13px;
  color: black;
}

.footer-row-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.footer-q-font {
  font-family: "mpReg";
  font-size: 12px;
  padding-top: 1rem;
  color: black;
}