.grid-container {
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.grid-row{
    display:grid;
    grid-template-columns: repeat(3, 300px);
    border-bottom: 1px solid rgba(77, 77, 77, .5);
    max-width: 900px;
}

.grid-cell{ 
    padding: 1rem 2rem;
}

.cell-headline-text {
    color: #c4c4c4;
    font-family: "futuraHeavy";
    font-size: 16px;
    letter-spacing: 2px;
}

.cell-text{
    color: #000000;
    font-family: 'mpBlkLt';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
}


.italic-text{
    font-style: italic;
    font-family: 'futuraLightBT';
    font-size: 13px;
}

@media screen and (max-width: 960px) {
    .grid-row{
        display:grid;
        grid-template-columns: repeat(3, 200px);
        border-bottom: 1px solid rgba(77, 77, 77, .5);
        max-width: 600px;
    }
}

@media screen and (max-width: 620px) {
    .grid-row{
        display:grid;
        grid-template-columns: repeat(3, 100px);
        border-bottom: 1px solid rgba(77, 77, 77, .5);
        max-width: 300px;
    }
    .cell-text{
        color: #000000;
        font-family: 'mpBlkLt';
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
    }
    .cell-headline-text {
        color: #c4c4c4;
        font-family: "futuraHeavy";
        font-size: 13px;
        letter-spacing: 1px;
    }
    .grid-cell{ 
        padding: 1rem 1rem;
    }
}
