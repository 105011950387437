.history-container > h1 {
  /* Heading 3 */
  font-family: "futuraMediumBT";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  letter-spacing: 0.39em;
  text-transform: uppercase;
  align-self: center;
  margin: 0px;

  color: #000000;
}

.history-container {
  padding: 200px;
  margin: 0px;
  display: flex;
  width: 100%;
  gap: 50px;
  flex-direction: column;
}

.slick-prev:before,
.slick-next:before {
  color: black !important;
}

@media only screen and (max-width: 900px) {
  [class="history-container"] {
    padding: 50px;
    width: 90vw;
  }
}
