.resource-desc-container {
    display:flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 5rem;
  }

.paa-desc-container {
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }  

.resource-right-box{
    background-color: rgba(247, 243, 252, 0.6);
    border-radius: 29px;
    display: flex;
    flex-direction: column;
    border-radius: 29px;
    max-width: 475px;
    padding:2rem 2.5rem;
    justify-content: center;
    margin-left: -1rem;
    z-index: -1;
}

.resource-center-box{
    background-color: rgba(247, 243, 252, 0.6);
    border-radius: 29px;
    display: flex;
    flex-direction: column;
    border-radius: 29px;
    max-width: 800px;
    padding:2rem;
    justify-content: center;
    margin-right: -1rem;
}

.paa-green-box {
    display: flex;
    background-color: rgba(214, 231, 216, .2);
    border-radius: 29px;
    flex-direction: column;
    padding: 2rem; 
    max-width: 761px;
    margin-top: 5rem;
    margin-right: 10rem;
    margin-bottom: -1rem;
  }

.resource-left-green-box {
    background-color: rgba(214, 231, 216, .2);
    border-radius: 29px;
    padding: 2rem 2.5rem; 
    max-width: 600px;
    height: max-content;
    margin-right: -1rem;
    margin-top: 5rem;
}

.p-right-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 540px;
    padding: 0 3rem;
    margin-left: 1rem;
}

.resource-h3-title {
    font-family: 'futuraHeavy';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    text-transform: uppercase;
    color: #000000;
    padding-bottom: 1rem;
    letter-spacing: 3px;
}

.resource-list-desc-container {
    display:flex;
    flex-direction: row;
    justify-content: center;
    padding: 1rem 0;
}

.resource-padding-right{
    padding-right: 5rem;
}
.resource-padding-left{
    padding-left: 2rem;
}

.resource-p-text {
    font-family: 'mpBlkLt';
    font-weight: 300;
    font-size: 16px;
    text-align: justify;
    color: #000000;
}

.italic-text{
    font-style: italic;
    font-family: 'futuraLightBT';
    color: #c4c4c4
}
.resource-indent-padding{
    margin-left: 1rem;
  }

.resource-grid-parent {
    display:inline-block;
}

.resource-grid-container {
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem 2rem;
    box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.05);
    border-radius: 14px;
    background-color: white;
  }
  
.resource-grid-row{
    display:grid;
    grid-template-columns: repeat(3, auto);
    border-bottom: 1px solid rgba(77, 77, 77, .5);
}
  
.resource-grid-cell{ 
    display: flex;
    flex-direction: column;
    margin: 1rem;
    justify-content: center;
}

.cell-headline-text {
    color: #c4c4c4;
    font-family: "futuraHeavy";
    font-size: 16px;
    letter-spacing: 2px;
}
.resource-italic-text{
    font-style: italic;
    font-family: 'futuraLightBT';
    color: #c4c4c4;
}

.cell-text{
    color: #000000;
    font-family: 'mpBlkLt';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
}
.resource-button {
    font-family: 'mpBlkLt';
    font-weight: 700;
    width: 190px;
    height: 40px;
    background-color: white;
    border: none;
    border-radius: 12px;
    cursor: pointer;
    margin-top: 1rem;
    color: #2B7632;
    border: 1px solid #2B7632;
    margin: 15px;
}

.resource-button:hover {
background-color: #2B7632;
color: white;
border: #2B7632;
}

.pca-purple-box {
    margin-right: 10rem;
    margin-bottom: -1rem;
  }


.rha-grid-container {
    position: relative;
    top: 80px;
}

.rha-flex {
    flex-direction: row;
}

.contact-width {
    width: 200px;
}

.areaOfStudy-width {
    width: 360px;
}

.year-width {
    width: 100px;
}

@media screen and (max-width: 960px) {
    .resource-desc-container {
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 5rem;
      }
      .resource-grid-container {
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 1rem 2rem;
        box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.05);
        border-radius: 14px;
        max-width:530px;
        margin-top: -1rem;
        background-color: white;
      }
      .p-right-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        max-width: 540px;
        padding: 2rem 3rem;
        margin-left: 1rem;
    }

    .paa-green-box {
        margin-right: 0;
        margin-bottom: 0;
    }

    .pca-purple-box {
    margin-right: 0;
    margin-bottom: 0;
    }

    .rha-flex {
        flex-direction: column;
    }

    .rha-grid-container {
        top: 20px;
    }

    .contact-width {
        width: 140px;
    }
    
    .areaOfStudy-width {
        width: 300px;
    }
    
    .year-width {
        width: 80px;
    }
  }

  @media screen and (max-width: 600px) {
    .resource-h3-title {
        font-family: 'futuraHeavy';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        text-transform: uppercase;
        color: #000000;
        padding-bottom: 1rem;
        letter-spacing: 3px;
    }
    .resource-p-text {
        font-family: 'mpBlkLt';
        font-weight: 300;
        font-size: 13px;
        text-align: justify;
        color: #000000;
    }
    .resource-grid-row{
        display:grid;
        grid-template-columns: repeat(2, 125px);
        border-bottom: 1px solid rgba(77, 77, 77, .5);
        max-width: 250px;
      }
    .cell-headline-text {
        color: #c4c4c4;
        font-family: "futuraHeavy";
        font-size: 13px;
        letter-spacing: 2px;
    }
    .cell-text{
        color: #000000;
        font-family: 'mpBlkLt';
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
    }
    .resource-italic-text{
        font-style: italic;
        font-family: 'futuraLightBT';
        color: #c4c4c4;
        font-size: 12px;
    }
    .resource-p-text {
        font-family: 'mpBlkLt';
        font-weight: 300;
        font-size: 16px;
        text-align: left;
        color: #000000;
    }
    
    .areaOfStudy-width {
        width: 120px;
    }

    .year-width {
        width: 60px;
    }
  }