* {
  font-family: 'futuraMediumBT';
}

.room-container {
  display: flex;
  flex-direction: row;
}

.calendar-mobile-header {
  margin-top: 15px;
}

.room-reservation-container {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 20px;
}
.display-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.purple-border {
  border-top: 2px solid #BE8EE3;
  border-left: 2px solid #BE8EE3;
  border-bottom: 2px solid #BE8EE3;
  display: flex;
  flex-direction: column;
  max-width: 1300px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  height: calc(100vh - 85px);
  overflow: scroll;
  padding: 1rem;
}

.header-text {
  margin-top: 4%;
  letter-spacing: .45rem;
}

.description-text {
  font-family: 'mpReg';
  padding:24px 40px;
}

.description-text- {
  font-family: 'mpReg';
}

.date-header {
  display: flex;
  flex-direction: row;
  width:  350px;
  padding: 1rem 0;
}

.calendar-mobile-header {
  display: flex;
  flex-direction: row;
}

.calendar-box {
  border-color: black;
}

.calendar-title {
  font-size: 100px;
}

/* -------------- */

.embedded-content {
  display: flex;
  flex-direction: row;
  gap: 20px;
  padding: 20px;
  width: 100%;
  box-sizing: border-box;
  overflow: auto;
}

.iframe-container {
  flex: 1;
  min-width: 300px;
  height: 800px;
}

.form-container {
  max-width: 40%;
}

.calendar-container {
  max-width: 60%;
}

.responsive-iframe {
  width: 100%;
  height: 100%;
  border: none;
}

@media (max-width: 768px) {
  .embedded-content {
    flex-direction: column;
  }
  .form-container, .calendar-container {
    max-width: 100%;
  }
}